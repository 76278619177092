
import React from "react";

function ControlledCarousel() {
  return (
    <section id="home" className="hero-block mb-5 mb-sm-6 mt-0">    
  <video autoPlay muted loop playsinline webkit-playsinline width="100%">
    <source src="../web video low.mp4" type="video/mp4" />
    Sorry, your browser doesn't support videos.
  </video>      
</section>
  );
}

export default ControlledCarousel;
