const GalleryDb = [
  {
    id: 1,
    image: " ../P1810832.jpeg",
    category: "Outlet",
  },
  {
    id: 2,
    image: "../PNX01005.jpeg",
    category: "Outlet",
  },
  {
    id: 3,
    image: "../PNX01003.jpeg",
    category: "Outlet",
  },
  {
    id: 4,
    image: "../P1810627.jpeg",
    category: "Outlet",
  },
  {
    id: 5,
    image: "../P1810761.jpeg",
    category: "Outlet",
  },
  {
    id: 6,
    image: "../IMG_9433.jpeg",
    category: "Outlet",
  },{
    id: 7,
    image: "../P1810627.jpeg",
    category: "Outlet",
  },
  {
    id: 8,
    image: "../IMG_9429.jpeg",
    category: "Outlet",
  },
  {
    id: 9,
    image: "../12.jpeg",
    category: "Event",
  },
  {
    id: 10,
    image: "../IMG_8050.jpeg",
    category: "Event",
  },
  {
    id: 11,
    image: "../IMG_8063.jpeg",
    category: "Event",
  },

  {
    id: 12,
    image: "../4.jpeg",
    category: "Event",
  },
  {
    id: 13,
    image: "../5.jpeg",
    category: "Event",
  },
  {
    id: 14,
    image: "../7.jpeg",
    category: "Event",
  },
  {
    id: 15,
    image: "../IMG_8499.jpeg",
    category: "Event",
  },
  {
    id: 16,
    image: "../14.jpeg",
    category: "Event",
  },
];

export default GalleryDb;
