const Productdb =[
    {
        id:1,
        image :"../city samosa only dish 2.png",
        span1:"Bite",
        span2:"into joy with",
        span3:"City Samosa!",
        
      
    },
    {
        id:2,
        image :"../vada pav 1 1.png",
        span1:"Feel",
        span2:"the spice in every",
        span3:"Vada Pav bite!",
           
    },
    {
        id:3,
        image :"../pattice pakoda 1 1.png",
       span1:"Crisp",
       span2:"outside, spicy inside",
       span3:"– Bread Pattis!",
    },
    {
        id:4,
        image :"../ulta vada pav 1 1.png",
        span1:"Ulta Vada Pav",
        span2:"a twist of ",
        span3:"flavors!",
    },
    {
        id:5,
        image :"../crispy vada pav 3 3.png",
         span1:"Crispy Vada Pav",
         span2:"– crunch into ",
         span3:"happiness!",
    },
  
    
   
]
    


export default Productdb;